var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"ProductGallery",staticClass:"modal fade",attrs:{"tabindex":"-1","id":"ProductGalleryModal","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"container",staticStyle:{"height":"85vh"}},[_c('div',{staticClass:"row px-3 mt-2 py-3 d-flex justify-content-between align-items-center"},[_vm._m(0),_c('div',[_c('button',{staticClass:"outline-none outline-none border-0 px-2 py-1 text-white",staticStyle:{"cusrsor":"pointer","border-radius":"6px","background":"#b3afb6"},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),(_vm.checkGalleryMode)?_c('button',{staticClass:"outline-none outline-none border-0 px-3 py-1 text-white mx-2",staticStyle:{"cusrsor":"pointer","border-radius":"6px"},style:(_vm.imageSrc
                    ? 'background:#4D1B7E'
                    : 'background:#4D1B7Ea6; pointer-events: none;'),on:{"click":function($event){return _vm.selectImageDone(_vm.imageSrc)}}},[_vm._v(" Save ")]):_vm._e()])]),_c('div',{staticClass:"row px-3 mt-2"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"form-group subheading d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.imageLink),expression:"imageLink"}],staticClass:"form-control-sm",staticStyle:{"width":"95%","background":"transparent","color":"#4d4950","border":"1px solid #b3afb6"},attrs:{"type":"text","id":"imgInput","placeholder":"Place image Link "},domProps:{"value":(_vm.imageLink)},on:{"input":function($event){if($event.target.composing)return;_vm.imageLink=$event.target.value}}}),(_vm.imageLink.length > 0)?_c('span',{staticClass:"ml-2",staticStyle:{"color":"red","cursor":"pointer"},attrs:{"id":"add_product_cancel_button"},on:{"click":function($event){_vm.imageLink = ''}}},[_c('i',{staticClass:"fa fa-times float-right"})]):_vm._e()])]),_c('div',{staticClass:"col-4"},[_c('button',{staticClass:"outline-none outline-none border-0 px-2 py-1 text-white w-100",class:_vm.spinner ? 'py-2' : '',staticStyle:{"cusrsor":"pointer","border-radius":"6px"},style:(_vm.imageLink.length > 0
                    ? 'background:#4D1B7E'
                    : 'background:#4D1B7Ea6; pointer-events: none;'),on:{"click":_vm.uploadImageLink}},[(!_vm.spinner)?_c('span',[_vm._v("Add image")]):_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])]),_c('div',[(_vm.isLoading)?_c('div',[_c('LoaderComp')],1):_c('div',{staticStyle:{"height":"70vh","overflow-x":"hidden","overflow-y":"scroll"}},[_c('div',{staticClass:"row p-3"},[_c('div',{staticClass:"col-6 mb-2",on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();}}},[_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/png,image/gif,image/jpeg,image/svg+xml","multiple":""},on:{"change":_vm.uploadFile}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.uploadingStatus),expression:"!uploadingStatus"}],staticClass:"productUpload d-flex flex-column justify-content-center align-items-center",on:{"dragenter":function($event){$event.preventDefault();return _vm.setActive.apply(null, arguments)},"dragover":function($event){$event.preventDefault();return _vm.setActive.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.setInactive.apply(null, arguments)},"drop":_vm.dragFile,"click":function($event){return _vm.$refs.fileInput.click()}}},[_c('i',{staticClass:"fa fa-cloud-upload fa-4x",attrs:{"aria-hidden":"true"}}),_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.dragActive ? "Drop to upload" : "Upload or Drop")+" ")])])]),_vm._l((_vm.fileList),function(file,fileIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileList.length),expression:"fileList.length"}],key:fileIndex,staticClass:"col-6 mb-2"},[_c('div',{staticClass:"image-div",on:{"click":function($event){return _vm.toggleSelectImage(file.image)}}},[_c('LazyImage',{class:_vm.imageSrc == file.image
                          ? 'selected-image'
                          : 'other-image',attrs:{"src":file.image,"alt":""}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageSrc == file.image),expression:"imageSrc == file.image"}],staticClass:"Polaris-Icon_yj27d"},[_c('svg',{staticClass:"selected-svg",attrs:{"viewBox":"0 0 20 20","focusable":"false","aria-hidden":"true"}},[_c('path',{attrs:{"fill":"white","d":"m8.315 13.859-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.436.436 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0"}})])])],1)])})],2)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"font-weight-bold",staticStyle:{"color":"#4d4950"}},[_vm._v(" Product Gallery ")])])
}]

export { render, staticRenderFns }